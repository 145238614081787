import { ethers } from "ethers";
import { defineStore } from "pinia";
import { useWeb3 } from "web3";
import { RiseID__factory } from "~/.nuxt/contracts/src/codegen/abi_types/factories/RiseID__factory";
import { RisePayToken__factory } from "~/.nuxt/contracts/src/codegen/abi_types/factories/RisePayToken__factory";

const web3 = useWeb3();
const runtimeConfig = useRuntimeConfig();
const provider = new ethers.JsonRpcProvider(
  runtimeConfig.public.RPC_URL as string
);

const factories = {
  RiseID: RiseID__factory,
  RisePayToken: RisePayToken__factory,
};

export const useWeb3Store = defineStore("web3", {
  state: (): { connectModal: boolean; signData: boolean; balance: number } => ({
    connectModal: false,
    signData: false,
    balance: 0,
  }),
  actions: {
    mint(account: string) {
      this.$client["/dashboard/accounts/balance/mint"].post({
        json: {
          amount: "100000000000000000000000",
          account,
          token: "USDC",
        },
      });
    },
    async connect() {
      return new Promise((resolve, _reject) => {
        const listenerFunction = (event: MessageEvent) => {
          if (
            ["rsk-connect-response", "connect-response"].includes(
              event.data.type
            )
          ) {
            window.removeEventListener("message", listenerFunction);
            resolve(true);
          }
        };
        window.addEventListener("message", listenerFunction);
        this.connectModal = true;
      });
    },
    async contract(name: keyof typeof factories, address: string) {
      return factories[name].connect(address);
    },
    async getAddress() {
      try {
        const address = await web3.getAddress();
        return address;
      } catch {
        return null;
      }
    },
    async getBalance(address: string) {
      const runtimeConfig = useRuntimeConfig();
      const risePayToken = await factories.RisePayToken.connect(
        runtimeConfig.public.RISEUSD_ADDRESS as string,
        provider
      );
      const cents =
        (await risePayToken["balanceOf(address)"](address)) /
        10000000000000000n;
      this.balance = parseInt(cents.toString()) / 100;
      return this.balance;
    },
    async signTypedData(payload: any) {
      const address = await web3.getAddress();
      if (!address) {
        await this.connect();
      }
      return web3.signTypedData(payload);
    },
  },
});
